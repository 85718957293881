export class Discounts {
  lsDiscounts: ProductDiscounts[];
  endDate: Date;
}

export class ProductDiscounts {
  product: string;
  licenseDiscount: string;
  enhancementDiscount: string;
  subscriptionDiscount: string;
  saasDiscount: string;
  hasDicount: boolean = false;
  status: string;
  description: string;
  logo: string;
  logoType: string;
}
